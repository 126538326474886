.login-form {
  background-color: #212529; /* Light background */
  padding: 20px;
  border-radius: 5px;
}

.login-form .form-control {
  width: 100%;
}

.button-box {
  margin-top: 20px;
}

.button-box .btn {
  width: 100%;
  margin-bottom: 10px;
}

.login-button {
  background-color: #003e6b; /* Match the button color from your site */
  border: none;
}

.login-button:hover {
  background-color: #002b5c;
}

.reset-button {
  background-color: #ffcc00; /* Accent color */
  border: none;
}

.reset-button:hover {
  background-color: #e6b800;
}

.google-button {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-button svg {
  margin-right: 8px;
}
