/* src/components/Header.css */

/* Header Top Section */
.header-top {
  background-color: #000000;
  padding: 10px 0;
}

.header-top-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Left-align the title and subtitle */
}

.header-title {
  color: #ffffff;
  font-size: 1.5em;
  font-weight: bold;
}

.header-subtitle {
  color: #ffffff;
  font-size: 1.2em;
}

/* Horizontal Line */
.header-line {
  border-top: 1px solid #ffffff;
  width: 100%;
  margin: 0;
}

/* Navigation Menu */
.header-navbar {
  background-color: #000000;
}

.header-nav .nav-link,
.header-nav .nav-dropdown {
  color: #ffffff;
  margin-left: 20px;
}

.header-nav .nav-link:hover,
.header-nav .dropdown-item:hover {
  color: #ffcc00;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(248,249,250,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Responsive Design */
@media (min-width: 768px) {
  .header-top-container {
    flex-direction: row;
    align-items: center;
  }

  .header-title {
    margin-right: 15px;
  }
}
