/* Set the modal background to dark */
.modal-content {
  background-color: #2c2f33; /* Updated background */
  color: #f8f9fa;
  border: 1px solid #6c757d;
  border-radius: 5px;
}

/* Style the modal header */
.modal-header {
  border-bottom: none;
}

/* Style the modal title */
.modal-title {
  color: #ffffff; /* Ensure the title is white */
}

/* Style the close button */
.modal-header .close {
  color: #ffffff;
  opacity: 1;
}

/* Style form labels inside the modal */
.modal-content label {
  color: #ffffff;
}

/* Style form controls (inputs) */
.modal-content .form-control {
  background-color: #212529; /* Dark gray background for inputs */
  color: #ffffff; /* White text */
  border: 1px solid #ced4da;
}

/* Existing styles for list group items inside modals */
.modal-content .list-group-item {
  color: #fff; /* Ensure text is readable */
  border: 1px solid #555; /* Optional border styling */
}

/* Apply alternating colors to list group items */
.modal-content .list-group-item:nth-child(odd) {
  background-color: #24272b; /* Dark gray */
}

.modal-content .list-group-item:nth-child(even) {
  background-color: #404550; /* Slightly lighter gray */
}

/* Change placeholder text color */
.modal-content .form-control::placeholder {
  color: #adb5bd; /* Light gray placeholder text */
}

/* Style buttons in the modal footer */
.modal-footer .btn {
  background-color: #007bff; /* Use your site's primary color */
  color: #ffffff;
  border: none;
}

.modal-footer .btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Optional: Style the modal footer */
.modal-footer {
  border-top: none;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9050; /* Higher than Bootstrap modals */
}

.success-toast-body {
  background-color: #d4edda; /* Light green background */
  color: #155724; /* Dark green text */
}

/* Style the custom checkbox container */
.custom-checkbox-container {
  display: flex;
  align-items: center;
  background-color: #212529; /* Match input background */
  color: #ffffff; /* White text */
  border: 1px solid #ced4da; /* Match input border */
  border-radius: 4px; /* Consistent border radius */
  padding: 0.375rem 0.75rem; /* Match input padding */
  margin-bottom: 1rem; /* Consistent spacing */
}

/* Style the checkbox input */
.custom-checkbox-container .form-check-input {
  margin-top: 0; /* Align checkbox vertically */
  margin-right: 0.5rem; /* Space between checkbox and label */
}

/* Style the checkbox label */
.custom-checkbox-container .form-check-label {
  margin-bottom: 0; /* Remove default margin */
  color: #ffffff; /* Ensure label text is white */
}

/* Optional: Adjust the size of the checkbox */
.custom-checkbox-container .form-check-input {
  width: 1.25rem;
  height: 1.25rem;
}

.custom-list-item {
  background-color: #2c2f33; /* Match the input background color */
  color: #fff; /* Ensure text is readable */
  border: 1px solid #555; /* Optional: match input border color */
}
