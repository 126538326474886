html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Primary button (Azure) */
.btn-primary {
  background-color: #007bff; /* Azure */
  border-color: #007bff;
  color: #ffffff; /* White text */
}
.btn-primary:hover {
  background-color: #005bbf; /* Darker Azure on hover */
  border-color: #005bbf;
}

/* Secondary button (Charcoal) */
.btn-secondary {
  background-color: #404550; /* Charcoal */
  border-color: #404550;
  color: #ffffff; /* White text */
}
.btn-secondary:hover {
  background-color: #2c2f33; /* Jet on hover */
  border-color: #2c2f33;
}

/* Success button (Muted Green) */
.btn-success {
  background-color: #4caf50; /* Muted, happy green */
  border-color: #4caf50;
  color: #ffffff; /* White text for contrast */
}
.btn-success:hover {
  background-color: #388e3c; /* Darker muted green on hover */
  border-color: #388e3c;
}

/* Danger button (Bittersweet Shimmer) */
.btn-danger {
  background-color: #ce4d4e; /* Bittersweet Shimmer */
  border-color: #ce4d4e;
  color: #ffffff; /* White text */
}
.btn-danger:hover {
  background-color: #a83838; /* Darker Bittersweet Shimmer on hover */
  border-color: #a83838;
}

/* Warning button (Muted Amber) */
.btn-warning {
  background-color: #d9900f; /* Muted amber */
  border-color: #d9900f;
  color: #ffffff; /* White text for contrast */
}
.btn-warning:hover {
  background-color: #b3790d; /* Darker muted amber on hover */
  border-color: #b3790d;
}

/* Info button (Onyx) */
.btn-info {
  background-color: #32363b; /* Onyx */
  border-color: #32363b;
  color: #ffffff; /* White text */
}
.btn-info:hover {
  background-color: #1f2226; /* Darker Onyx on hover */
  border-color: #1f2226;
}

/* Light button (White) */
.btn-light {
  background-color: #ffffff; /* White */
  border-color: #ffffff;
  color: #101214; /* Night text */
}
.btn-light:hover {
  background-color: #eaeaea; /* Light gray on hover */
  border-color: #eaeaea;
}

/* Dark button (Night) */
.btn-dark {
  background-color: #101214; /* Night */
  border-color: #101214;
  color: #ffffff; /* White text */
}
.btn-dark:hover {
  background-color: #060707; /* Black on hover */
  border-color: #060707;
}
