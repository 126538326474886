.dashboard-card {
  background-color: #000000;
  color: #f8f9fa;
  border: 1px solid #6c757d;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition */
}

.dashboard-card:hover {
  background-color: #1a1a1a; /* Slightly lighter color on hover */
  border-color: #ffffff; /* Optional: Change border color on hover */
  filter: brightness(1.2); /* Increase brightness by 20% */
  /* box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); */
}

.dashboard-card:hover .card-title,
.dashboard-card:hover .card-text {
  color: #ffcc00; /* Change text color to a golden hue */
}

.dashboard-card .card-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.dashboard-card .card-text {
  font-size: 2rem;
  font-weight: bold;
}

/* Ensure the card body stretches to fill available space */
.dashboard-card .card-body {
  display: flex;
  flex-direction: column;
}

/* Adjust the chart container to flex-grow */
.dashboard-card .card-body > div {
  flex-grow: 1;
}

@media (max-width: 576px) {
  .dashboard-card .recharts-wrapper {
    min-height: 300px;
  }
}
