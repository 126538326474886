/* Style the table container */
.table-container {
  background-color: #2c2f33; /* Updated background */
  color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
}

/* Style the table */
.table {
  background-color: #2c2f33; /* Updated background */
  color: #f8f9fa;
}

.table tbody tr:nth-of-type(odd) {
  background-color: #333842; /* Slightly lighter gray for odd rows */
}

.table tbody tr:nth-of-type(even) {
  background-color: #2c2f33; /* Match the table background */
}

.table-hover tbody tr:hover {
  background-color: #3b4048; /* Slightly lighter gray on hover */
}

/* Style the search input */
.table-search-input {
  background-color: #212529; /* Dark gray background */
  color: #ffffff; /* White text */
  border: 1px solid #ced4da;
}

.table-search-input::placeholder {
  color: #adb5bd; /* Light gray placeholder text */
}

/* Optional: Adjust padding and margins */
.table th,
.table td {
  padding: 12px;
}

.table thead th {
  border-bottom: 2px solid #dee2e6;
}

.table-container h2 {
  color: #ffffff; /* Ensure the heading is white */
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-drivers-card {
  background-color: #241a1a !important; /* Slightly lighter red */
  color: #fff; /* Ensure text is readable */
  padding: 5px;
  border-radius: 5px;
}

.table-striped tbody tr td.no-drivers-cell {
  --bs-table-bg: #241a1a;
  --bs-table-bg-state: #241a1a;
  color: #fff;
}

/* Style for cells with no company assigned */
.table-striped tbody tr td.no-company-cell {
  --bs-table-bg: #241a1a;
  --bs-table-bg-state: #241a1a;
  color: #fff; /* White text for readability */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .table th,
  .table td {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .table th,
  .table td {
    padding: 8px; /* Reduce padding */
    font-size: 12px; /* Reduce font size */
  }

  .table-container {
    padding: 10px; /* Reduce container padding */
  }

  .table thead th {
    font-size: 12px; /* Reduce header font size */
  }
}
