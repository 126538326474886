.footer {
  background-color: #000000; /* Black background */
  color: #ffffff;
  padding: 20px 50px;
  font-family: "Lato", sans-serif;
  position: relative;
  /* Remove any fixed height if present */
}

.footer::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 0; /* or another small value */
  background-color: #000000;
  z-index: -1;
}

.footer-line {
  border-top: 1px solid #ffffff; /* Thin white line */
  width: calc(100% - 100px); /* Subtract total horizontal padding */
  margin: 30px auto 30px auto; /* Increase top and bottom margin */
}

.footer-logo {
  max-width: 500px; /* Maintains an upper limit for large screens */
  width: 100%;
  height: auto;
  display: block;
  margin-left: 0; /* Left-align the logo */
}

.footer-left {
  text-align: left; /* Align text in the left column */
}

.footer-right-group {
  display: flex;
  justify-content: flex-end; /* Align grouped column contents to the right */
}

.footer-social-media {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align social media title and icons */
}

.social-title {
  font-size: 16px; /* Update font size to match site */
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  justify-content: center; /* Center-align icons */
}

.social-icons a {
  margin: 0 10px;
  color: #ffffff;
}

.social-icons a:hover {
  color: #ffcc00;
}

.footer-contact {
  text-align: left; /* Left-align contact info */
}

.contact-info {
  font-size: 14px; /* Update font size to match site */
  margin-left: 0; /* Ensure no extra left margin */
}

.footer-location {
  text-align: center; /* Center-align location info */
  font-size: 14px; /* Update font size to match site */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .footer-left,
  .footer-social,
  .footer-contact,
  .footer-location {
    text-align: center; /* Center-align text on smaller screens */
  }

  .footer {
    padding: 20px 20px; /* Reduce horizontal padding on small screens */
  }

  .footer-line {
    width: calc(100% - 40px); /* Adjust width to match reduced padding */
    margin: 30px auto; /* Keep increased margin on smaller screens */
  }

  .social-icons {
    justify-content: center; /* Center icons */
  }

  /* Ensure the logo is centered */
  .footer-left {
    text-align: center;
    margin-bottom: 20px;
  }

  /* Responsive Styling for smaller screens */
  .footer-left .footer-logo {
    max-width: 80%; /* Shrink down to 80% of the container */
    margin: 0 auto; /* Center the image */
  }

  /* Ensure the right group stacks and centers */
  .footer-right-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Override the row that was previously justifying content to the end */
  .footer .justify-content-end {
    justify-content: center !important;
  }
}
