/* Style for dark-themed cards */
.card-dark {
  background-color: #0f1112; /* Updated to match body background */
  color: #f8f9fa;
  border: 1px solid #6c757d;
  border-radius: 5px;
}

.card-dark .card-title {
  color: #ffffff; /* Ensure card title is white */
}

.card-dark .card-text {
  color: #f8f9fa; /* Ensure card text is light */
}

.card-dark .card-body {
  padding: 15px; /* Adjust padding if necessary */
}

/* Style for cards with no company assigned */
.no-company-card {
  background-color: #241a1a; /* Red background */
  color: #fff; /* White text */
  padding: 5px;
  border-radius: 5px;
}
