.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container fills the viewport height */
}

.content {
  flex: 1; /* Allows the content area to grow and fill available space */
}

/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #0d0e0f; /* Adjusted to dark gray */
  color: #f8f9fa;
}

/* Header Styles */
.title-row {
  background-color: #000000; /* Adjust as needed */
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
}

.title {
  font-size: 2em;
  margin: 0;
  color: #007bff; /* Use the primary color from the target site */
}

/* Login Form Styles */
.login-form-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background-color: #212529;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.login-form .input-label {
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
}

.login-form .input-text {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.button-box {
  text-align: center;
}

.primary-button {
  background-color: #007bff; /* Primary button color */
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.primary-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Custom Button Styles */
.btn {
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
}

.btn-primary {
  background-color: #5a8dee; /* Muted blue */
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #4a7cd6; /* Slightly darker on hover */
}

.btn-warning {
  background-color: #f0ad4e; /* Muted orange */
  color: #ffffff;
}

.btn-warning:hover {
  background-color: #e99a34;
}

.btn-danger {
  background-color: #d9534f; /* Muted red */
  color: #ffffff;
}

.btn-danger:hover {
  background-color: #c9302c;
}

/* Small button adjustments */
.btn-sm {
  padding: 6px 12px;
  font-size: 12px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .title {
    font-size: 1.5em;
  }

  .login-form-container {
    margin: 20px;
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 1.2em;
  }

  .login-form-container {
    margin: 10px;
    padding: 10px;
  }
}
